<template>
    <svg v-if="$route && $route.meta.lang === 'fr'" viewBox="0 0 225 225" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M216.383 69.4149L216.622 69.2952L215.904 67.6197L215.665 67.7393C198.311 27.8856 158.577 0 112.5 0C50.5053 0 0 50.5053 0 112.5C0 127.819 3.1117 142.301 8.61702 155.585L8.37766 155.705L9.09575 157.38L9.33511 157.261C26.6888 197.114 66.4229 225 112.5 225C174.495 225 225 174.495 225 112.5C225 97.3005 222.008 82.6995 216.383 69.4149ZM1.79521 112.62C1.79521 51.5824 51.4628 1.91486 112.5 1.91486C157.859 1.91486 196.995 29.3218 213.989 68.5771L169.707 87.367C159.894 65.4654 137.872 50.266 112.5 50.266C77.9122 50.266 49.7872 78.3909 49.7872 112.979C49.7872 121.237 51.4628 129.136 54.3351 136.316L10.2926 154.987C4.78724 141.941 1.79521 127.58 1.79521 112.62ZM56.0106 135.598C53.1383 128.537 51.5825 120.878 51.5825 112.859C51.5825 79.2287 78.8697 51.9415 112.5 51.9415C137.274 51.9415 158.577 66.7819 168.152 87.9654L56.0106 135.598ZM168.87 89.6409C171.862 96.8218 173.537 104.721 173.537 112.979C173.537 146.609 146.25 173.896 112.62 173.896C87.6064 173.896 66.1835 158.816 56.7287 137.274L168.87 89.6409ZM112.5 223.324C67.141 223.324 28.0053 195.918 11.0106 156.662L55.0532 137.992C64.7473 160.133 86.8883 175.691 112.62 175.691C147.207 175.691 175.332 147.566 175.332 112.979C175.332 104.481 173.657 96.3431 170.545 89.0425L214.707 70.2526C220.213 83.2978 223.205 97.6595 223.205 112.739C223.205 173.657 173.537 223.324 112.5 223.324Z"
            fill="black"
        />
        <path
            d="M38.4174 120.638C37.3403 119.92 36.0238 119.8 34.468 120.04L25.6116 121.715C24.0557 121.954 22.9786 122.672 22.1408 123.63C21.4228 124.587 21.1834 125.784 21.4228 127.22L22.1408 131.409C22.1408 131.649 22.2605 131.649 22.4999 131.649L40.9307 128.297C41.0504 128.297 41.1701 128.178 41.1701 127.938L40.452 123.75C40.2127 122.313 39.4946 121.356 38.4174 120.638ZM39.1355 126.622L23.8164 129.494C23.6967 129.494 23.6967 129.494 23.6967 129.375L23.218 126.981C23.0983 126.143 23.218 125.425 23.8164 124.827C24.2951 124.228 25.1329 123.869 26.21 123.63L34.9467 122.074C36.0238 121.954 36.8616 121.954 37.5797 122.313C38.2978 122.672 38.6568 123.271 38.8962 124.109L39.3749 126.502C39.2552 126.502 39.2552 126.622 39.1355 126.622Z"
            fill="black"
        />
        <path
            d="M14.8405 104.002C14.7208 104.002 14.7208 103.882 14.6011 104.002C14.4814 104.002 14.4814 104.122 14.4814 104.242V105.319C14.4814 105.438 14.4814 105.558 14.7208 105.678L17.7128 107.473C17.8325 107.473 17.8325 107.593 17.9522 107.473C18.0719 107.473 18.0719 107.353 18.0719 107.234V106.396C18.0719 106.276 18.0719 106.156 17.8325 106.037L14.8405 104.002Z"
            fill="black"
        />
        <path
            d="M39.0161 110.945L39.2555 102.806C39.2555 102.567 39.1358 102.567 39.0161 102.567H37.8193C37.58 102.567 37.58 102.687 37.58 102.806L37.3406 109.15C37.3406 109.269 37.3406 109.269 37.2209 109.269L30.3991 109.03C30.2794 109.03 30.2794 109.03 30.2794 108.91L30.3991 104.841C30.3991 104.602 30.2794 104.602 30.1597 104.602H28.9629C28.7236 104.602 28.7236 104.721 28.7236 104.841L28.6039 108.91C28.6039 109.03 28.6039 109.03 28.4842 109.03L21.6624 108.791C21.5427 108.791 21.5427 108.791 21.5427 108.671L21.7821 102.328C21.7821 102.088 21.6624 102.088 21.5427 102.088H20.3459C20.1065 102.088 20.1065 102.208 20.1065 102.328L19.8672 110.466C19.8672 110.705 19.9869 110.705 20.1065 110.705L38.7768 111.304C38.8964 111.304 39.0161 111.184 39.0161 110.945Z"
            fill="black"
        />
        <path
            d="M41.4096 89.9998L24.2952 81.3828C24.0559 81.2632 23.9362 81.3828 23.9362 81.5025L23.5772 82.9387C23.5772 83.0583 23.5772 83.178 23.6968 83.2977L37.8192 89.9998C37.8192 89.9998 37.8192 89.9998 37.8192 90.1195C37.8192 90.1195 37.8192 90.1195 37.6995 90.1195L22.0213 89.1621C21.9016 89.1621 21.7819 89.1621 21.6623 89.4014L21.3032 90.8376C21.3032 90.9573 21.3032 91.077 21.4229 91.1966L40.5718 92.0344C40.6915 92.0344 40.8112 91.9147 40.9309 91.795L41.2899 90.2392C41.649 90.2392 41.649 90.1195 41.4096 89.9998Z"
            fill="black"
        />
        <path
            d="M48.2314 70.9708C48.1117 70.8511 47.9921 70.9708 47.8724 71.0905L44.8804 76.7155C44.8804 76.8351 44.7607 76.8351 44.7607 76.7155L38.657 73.6038C38.5373 73.6038 38.5373 73.4841 38.657 73.4841L40.4522 69.8937C40.5719 69.774 40.4522 69.6543 40.3325 69.5346L39.2554 68.9362C39.1357 68.8165 39.016 68.9362 38.8963 69.0559L37.1011 72.6463C37.1011 72.766 36.9814 72.766 36.9814 72.6463L30.8777 69.5346C30.758 69.5346 30.758 69.415 30.8777 69.415L33.8697 63.7899C33.9894 63.6703 33.8697 63.5506 33.75 63.4309L32.6729 62.9522C32.5532 62.8325 32.4336 62.9522 32.3139 63.0718L28.6038 70.3724C28.4841 70.4921 28.6038 70.6117 28.7234 70.7314L45.3591 79.3484C45.4788 79.4681 45.5984 79.3484 45.7181 79.2288L49.4282 71.9282C49.5479 71.8085 49.4282 71.6889 49.3085 71.5692L48.2314 70.9708Z"
            fill="black"
        />
        <path
            d="M53.6172 65.2264C53.7369 65.3461 53.8566 65.3461 53.9763 65.2264L59.1226 59.003C59.2422 58.8834 59.2422 58.7637 59.1226 58.644L58.2848 57.9259C58.1651 57.8062 58.0454 57.8062 57.9257 57.9259L53.8566 62.8328C53.8566 62.8328 53.7369 62.9525 53.7369 62.8328L40.572 51.8222C40.4523 51.7025 40.3327 51.7025 40.213 51.8222L39.2555 52.8993C39.1359 53.019 39.1359 53.1387 39.2555 53.2584L53.6172 65.2264Z"
            fill="black"
        />
        <path
            d="M61.0376 50.8643C61.8753 52.0611 62.9525 52.6595 64.2689 52.7792C65.4657 52.8989 66.6626 52.5398 67.7397 51.7021C68.8168 50.8643 69.5349 49.7872 69.6546 48.4707C69.7743 47.2739 69.4152 46.0771 68.5775 44.8803L62.4737 36.8617C61.636 35.6648 60.5589 35.0664 59.2424 34.9468C58.0456 34.8271 56.8487 35.1861 55.6519 36.0239C54.5748 36.8616 53.8567 37.9388 53.737 39.1356C53.6174 40.3324 53.9764 41.5292 54.8142 42.726L61.0376 50.8643ZM56.7291 37.4601C57.4471 36.9813 58.1652 36.742 58.8833 36.8617C59.6014 36.9813 60.3195 37.4601 60.7982 38.0585L67.0216 46.1967C67.62 46.9148 67.7397 47.6329 67.7397 48.351C67.62 49.0691 67.261 49.7872 66.6626 50.2659C66.0642 50.7446 65.3461 50.984 64.5083 50.8643C63.7902 50.7446 63.0721 50.2659 62.5934 49.6675L56.37 41.5292C55.7716 40.8111 55.5322 40.093 55.6519 39.375C55.6519 38.6569 56.011 37.9388 56.7291 37.4601Z"
            fill="black"
        />
        <path
            d="M78.0318 44.4017C78.1514 44.5214 78.2712 44.6411 78.3908 44.5214L79.7073 43.923C79.827 43.8033 79.9467 43.6837 79.827 43.564L76.3562 36.0241C76.3562 35.9044 76.3562 35.9044 76.3562 35.9044L78.9892 34.7076C80.186 34.1092 80.9041 33.2714 81.2632 32.0746C81.6222 30.8778 81.3829 29.5613 80.7844 28.1252C80.0664 26.689 79.2286 25.6119 78.0318 25.1331C76.835 24.5347 75.6382 24.5347 74.4413 25.1331L70.2525 27.048C70.1328 27.1677 70.0131 27.2874 70.1328 27.4071L78.0318 44.4017ZM72.7658 27.7661L75.0397 26.689C75.7578 26.3299 76.5956 26.3299 77.3137 26.8087C78.0318 27.1677 78.6302 27.8858 79.1089 28.9629C79.5876 29.9203 79.7073 30.8778 79.468 31.7156C79.2286 32.5533 78.7499 33.1517 78.0318 33.3911L75.7578 34.4682C75.6382 34.4682 75.6382 34.4682 75.6382 34.4682L72.6461 28.0055C72.6461 27.8858 72.6461 27.7661 72.7658 27.7661Z"
            fill="black"
        />
        <path
            d="M93.8298 38.6569L95.1463 38.4175C95.2659 38.4175 95.3856 38.2978 95.3856 38.0585L93.5904 29.9202C93.5904 29.8005 93.5904 29.8005 93.7101 29.8005L96.4627 29.2021C97.7792 28.9628 98.7367 28.2447 99.3351 27.1675C99.9335 26.0904 100.053 24.7739 99.6941 23.2181C99.3351 21.6622 98.617 20.4654 97.6595 19.7473C96.7021 18.9096 95.5053 18.6702 94.1888 19.0292L89.7606 19.9867C89.6409 19.9867 89.5212 20.1064 89.5212 20.3457L93.4707 38.6569C93.5904 38.5372 93.7101 38.6569 93.8298 38.6569ZM93.2314 28.125L91.6755 21.1835C91.6755 21.0638 91.6755 21.0638 91.7952 21.0638L94.1888 20.5851C95.0266 20.4654 95.7447 20.5851 96.3431 21.1835C96.9415 21.7819 97.4202 22.5 97.6595 23.5771C97.8989 24.6542 97.7792 25.6117 97.4202 26.3298C97.0611 27.0479 96.4627 27.5266 95.625 27.7659L93.2314 28.2446C93.351 28.2446 93.2314 28.125 93.2314 28.125Z"
            fill="black"
        />
        <path
            d="M110.346 36.2637L118.484 36.3834C118.723 36.3834 118.723 36.2637 118.723 36.144V34.9472C118.723 34.7078 118.604 34.7078 118.484 34.7078L112.141 34.5882C112.021 34.5882 112.021 34.5882 112.021 34.4685L112.141 27.6466C112.141 27.527 112.141 27.527 112.261 27.527L116.33 27.6466C116.569 27.6466 116.569 27.527 116.569 27.4073V26.2105C116.569 25.9711 116.449 25.9711 116.33 25.9711L112.261 25.8514C112.141 25.8514 112.141 25.8515 112.141 25.7318L112.261 18.91C112.261 18.7903 112.261 18.7903 112.38 18.7903L118.723 18.91C118.963 18.91 118.963 18.7903 118.963 18.6706V17.4738C118.963 17.2344 118.843 17.2344 118.723 17.2344L110.585 17.1147C110.346 17.1147 110.346 17.2344 110.346 17.3541L109.987 36.0243C110.106 36.144 110.106 36.2637 110.346 36.2637Z"
            fill="black"
        />
        <path
            d="M126.383 37.4605L127.699 37.8195C127.819 37.8195 127.939 37.8196 128.059 37.5802L131.888 23.3382C131.888 23.3382 131.888 23.2185 132.008 23.2185C132.008 23.2185 132.008 23.2185 132.128 23.3382L133.324 29.8009C133.324 29.9206 133.444 30.0403 133.564 30.0403C133.683 30.0403 133.803 30.0403 133.923 29.9206L138.231 24.894H138.351C138.351 24.894 138.351 24.894 138.351 25.0137L134.521 39.2557C134.521 39.3754 134.521 39.4951 134.761 39.6147L136.077 39.9738C136.197 39.9738 136.316 39.9738 136.436 39.7344L141.223 21.6626C141.223 21.5429 141.223 21.4233 140.984 21.3036L139.548 20.9446C139.428 20.9446 139.308 20.9445 139.189 21.0642L134.521 26.4499C134.521 26.4499 134.521 26.4499 134.402 26.4499C134.402 26.4499 134.402 26.4499 134.282 26.3302L132.846 19.3887C132.846 19.269 132.726 19.1493 132.606 19.1493L131.17 18.7903C131.051 18.7903 130.931 18.7903 130.811 19.0297L126.024 37.1015C126.144 37.3408 126.263 37.4605 126.383 37.4605Z"
            fill="black"
        />
        <path
            d="M151.277 47.3932C151.396 47.5129 151.516 47.3932 151.636 47.2736L152.234 46.3161C152.354 46.1964 152.234 46.0767 152.114 45.9571L146.489 42.8453C146.37 42.8453 146.37 42.7257 146.489 42.7257L149.721 36.7416C149.721 36.6219 149.84 36.6219 149.84 36.7416L153.431 38.6565C153.55 38.7762 153.67 38.6565 153.79 38.5368L154.388 37.4597C154.508 37.34 154.388 37.2204 154.269 37.1007L150.798 35.1858C150.678 35.1858 150.678 35.0661 150.798 35.0661L154.029 29.082C154.029 28.9624 154.149 28.9624 154.149 29.082L159.774 32.1938C159.894 32.3134 160.013 32.1937 160.133 32.0741L160.731 31.1166C160.851 30.997 160.731 30.8773 160.612 30.7576L153.431 26.8081C153.311 26.6884 153.191 26.8081 153.072 26.9278L144.096 43.3241C143.976 43.4437 144.096 43.5634 144.215 43.6831L151.277 47.3932Z"
            fill="black"
        />
        <path
            d="M157.979 52.0616L159.056 53.019C159.176 53.1387 159.295 53.1387 159.415 53.019L168.87 42.4871H168.989C168.989 42.4871 168.989 42.4871 168.989 42.6068L163.484 56.8488C163.484 56.9685 163.484 57.0882 163.604 57.2078L164.801 58.285C164.92 58.4046 165.04 58.4046 165.16 58.285L177.606 44.2823C177.726 44.1626 177.726 44.0429 177.606 43.9232L176.529 42.9658C176.41 42.8461 176.29 42.8461 176.17 42.9658L166.716 53.4977H166.596C166.596 53.4977 166.596 53.4977 166.596 53.378L172.101 39.136C172.101 39.0163 172.101 38.8967 171.981 38.777L170.785 37.6999C170.665 37.5802 170.545 37.5802 170.426 37.6999L157.979 51.5828C157.739 51.7025 157.739 51.9419 157.979 52.0616Z"
            fill="black"
        />
        <path
            d="M186.223 56.7287C186.223 56.8484 186.223 56.8484 186.223 56.8484L172.101 66.6622C171.981 66.7819 171.981 66.9016 171.981 67.0213L172.819 68.2181C172.939 68.3378 173.058 68.3377 173.178 68.3377L187.3 58.5239H187.42L189.455 61.516C189.574 61.6356 189.694 61.6356 189.814 61.6356L190.771 60.9176C190.891 60.7979 190.891 60.6782 190.891 60.5585L185.625 53.0186C185.505 52.8989 185.385 52.8989 185.266 52.8989L184.308 53.617C184.189 53.7367 184.189 53.8564 184.189 53.976L186.223 56.7287Z"
            fill="black"
        />
        <path
            d="M81.9815 180.838L78.2714 178.803C78.1517 178.684 78.032 178.803 77.9123 178.923L69.0559 195.439C68.9362 195.559 69.0559 195.678 69.1756 195.798L72.8857 197.832C74.0825 198.551 75.399 198.67 76.4761 198.192C77.673 197.832 78.6304 196.875 79.3485 195.559L83.657 187.66C84.3751 186.343 84.6144 185.027 84.2554 183.83C84.016 182.513 83.1783 181.556 81.9815 180.838ZM81.8618 186.702L77.673 194.481C77.1942 195.439 76.4761 196.037 75.7581 196.396C75.04 196.755 74.3219 196.636 73.6038 196.277L71.4495 195.199C71.3299 195.199 71.3299 195.08 71.4495 195.08L78.8698 181.317C78.8698 181.197 78.9895 181.197 78.9895 181.317L81.1437 182.513C81.8618 182.872 82.3405 183.471 82.4602 184.309C82.5799 184.907 82.3405 185.745 81.8618 186.702Z"
            fill="black"
        />
        <path
            d="M106.755 188.976L105.319 188.736C105.08 188.736 105.08 188.736 104.96 188.976L102.207 202.859C101.968 203.816 101.609 204.654 101.01 205.133C100.412 205.611 99.694 205.851 98.8562 205.611C98.0184 205.492 97.42 205.013 97.061 204.295C96.7019 203.577 96.5823 202.739 96.8216 201.782L99.5743 187.899C99.5743 187.779 99.5743 187.659 99.3349 187.54L97.8988 187.3C97.6594 187.3 97.6594 187.3 97.5397 187.54L94.9067 201.183C94.5477 202.739 94.787 204.056 95.3854 205.252C96.1035 206.33 97.061 207.048 98.4972 207.287C99.9333 207.526 101.13 207.287 102.207 206.569C103.284 205.731 103.883 204.654 104.242 203.098L106.875 189.455C106.994 189.215 106.875 189.095 106.755 188.976Z"
            fill="black"
        />
        <path
            d="M127.101 199.149C127.101 199.03 127.101 199.03 127.221 199.03C128.059 198.551 128.657 197.952 129.136 196.995C129.495 196.037 129.734 195.08 129.614 193.883C129.495 192.327 128.896 191.131 127.939 190.173C126.981 189.335 125.904 188.857 124.588 189.096L120.04 189.575C119.801 189.575 119.801 189.694 119.801 189.934L121.835 208.484C121.835 208.724 121.955 208.724 122.194 208.724L123.63 208.604C123.87 208.604 123.87 208.484 123.87 208.245L122.912 199.867C122.912 199.748 122.912 199.748 123.032 199.748L125.426 199.508C125.426 199.508 125.545 199.508 125.545 199.628L129.255 207.766C129.375 207.886 129.495 208.006 129.614 208.006L131.051 207.886C131.17 207.886 131.29 207.766 131.29 207.647V207.527L127.101 199.149ZM125.186 197.833L122.673 198.072C122.553 198.072 122.553 198.072 122.553 197.952L121.835 191.011C121.835 190.891 121.835 190.891 121.955 190.891L124.468 190.652C125.306 190.532 126.024 190.772 126.622 191.37C127.221 191.968 127.58 192.806 127.699 193.883C127.819 194.96 127.699 195.798 127.221 196.516C126.622 197.354 126.024 197.833 125.186 197.833Z"
            fill="black"
        />
        <path
            d="M156.423 199.149L145.293 183.591C145.173 183.471 145.053 183.351 144.934 183.471L143.378 184.069C143.258 184.189 143.138 184.189 143.138 184.428L146.609 203.218C146.609 203.338 146.609 203.338 146.729 203.458C146.849 203.458 146.849 203.458 146.968 203.458L148.285 202.859C148.404 202.74 148.524 202.62 148.524 202.5L147.806 199.029V198.91C147.806 198.91 147.806 198.91 147.926 198.79L152.474 196.875H152.593C152.593 196.875 152.593 196.875 152.713 196.995L154.628 199.867C154.747 199.987 154.867 199.987 154.987 199.987L156.303 199.388C156.543 199.508 156.543 199.389 156.423 199.149ZM151.636 195.678L147.686 197.354C147.686 197.354 147.567 197.354 147.567 197.234L145.412 186.463V186.343C145.412 186.343 145.412 186.343 145.532 186.343L151.755 195.439C151.636 195.678 151.636 195.678 151.636 195.678Z"
            fill="black"
        />
        <path
            d="M169.947 178.444C169.827 178.444 169.827 178.444 169.947 178.444C170.545 176.769 170.306 175.213 168.989 173.657C167.912 172.46 166.835 171.742 165.519 171.623C164.322 171.503 163.005 171.982 161.808 172.939L158.697 175.452C158.577 175.572 158.577 175.692 158.697 175.811L170.545 190.293C170.665 190.412 170.784 190.412 170.904 190.293L174.136 187.66C175.332 186.702 175.931 185.625 176.05 184.309C176.17 182.992 175.691 181.795 174.614 180.479C173.417 178.923 171.742 178.205 169.947 178.444ZM165.519 180.958L161.33 175.811V175.692L163.005 174.256C163.723 173.657 164.561 173.418 165.399 173.537C166.237 173.657 166.955 174.136 167.673 174.974C168.391 175.811 168.63 176.649 168.63 177.367C168.63 178.205 168.152 178.923 167.433 179.522L165.758 180.958C165.519 181.077 165.519 181.077 165.519 180.958ZM173.058 186.463L171.263 187.899H171.144L166.476 182.274V182.154L168.271 180.718C168.989 180.12 169.827 179.881 170.665 180C171.503 180.12 172.34 180.718 173.058 181.676C173.776 182.633 174.136 183.471 174.136 184.309C174.255 185.147 173.896 185.865 173.058 186.463Z"
            fill="black"
        />
        <path
            d="M193.285 163.485C193.165 163.365 193.045 163.365 192.925 163.604L189.455 168.87C189.455 168.99 189.335 168.99 189.335 168.87L175.093 159.296C174.973 159.176 174.854 159.176 174.734 159.416L173.896 160.612C173.777 160.732 173.777 160.852 174.016 160.971L189.574 171.384C189.694 171.503 189.814 171.503 189.933 171.264L194.481 164.562C194.601 164.442 194.601 164.322 194.362 164.203L193.285 163.485Z"
            fill="black"
        />
        <path
            d="M204.056 140.745L202.979 140.386C202.859 140.386 202.739 140.386 202.62 140.505L200.705 146.609C200.705 146.729 200.585 146.729 200.585 146.729L194.003 144.574C193.883 144.574 193.883 144.455 193.883 144.455L195.08 140.625C195.08 140.505 195.08 140.386 194.96 140.266L193.883 139.907C193.763 139.907 193.644 139.907 193.524 140.027L192.327 143.856C192.327 143.976 192.207 143.976 192.207 143.976L185.745 141.941C185.625 141.941 185.625 141.822 185.625 141.822L187.54 135.718C187.54 135.598 187.54 135.479 187.42 135.359L186.343 135C186.223 135 186.104 135 185.984 135.12L183.471 142.899C183.471 143.019 183.471 143.138 183.59 143.258L201.423 149.003C201.543 149.003 201.662 149.003 201.782 148.883L204.295 141.104C204.295 140.984 204.176 140.864 204.056 140.745Z"
            fill="black"
        />
    </svg>
    <svg v-else viewBox="0 0 188 188" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1699 85.1599C14.8099 86.0099 15.7699 86.5399 17.0599 86.7299C17.8799 86.8499 18.6199 86.8099 19.2799 86.5899C19.9399 86.3799 20.5199 86.0599 21.0299 85.6499C21.5399 85.2399 22.1599 84.6599 22.8899 83.9299C23.6399 83.1999 24.1899 82.6799 24.5499 82.3699C24.9099 82.0599 25.3099 81.8299 25.7399 81.6599C26.1799 81.4999 26.6799 81.4599 27.2399 81.5399C28.0799 81.6699 28.7199 81.9999 29.1599 82.5399C29.5999 83.0799 29.7699 83.6899 29.6699 84.3899C29.5599 85.0999 29.2099 85.6399 28.6099 85.9999C28.0099 86.3699 27.2699 86.4799 26.3999 86.3499L25.9099 86.2799C25.7499 86.2599 25.6499 86.3299 25.6299 86.4899L25.4499 87.6999C25.4299 87.8599 25.4999 87.9599 25.6599 87.9799L26.2299 88.0599C27.5299 88.2599 28.6399 88.0299 29.5599 87.3999C30.4799 86.7599 31.0299 85.8299 31.2199 84.6099C31.3999 83.3899 31.1399 82.3299 30.4399 81.4399C29.7399 80.5399 28.7099 79.9899 27.3599 79.7899C26.5999 79.6799 25.9399 79.7099 25.3599 79.8999C24.7799 80.0799 24.2299 80.3799 23.7099 80.7999C23.1799 81.2199 22.4899 81.8699 21.6099 82.7499C20.9399 83.4099 20.3999 83.8999 19.9999 84.2299C19.5999 84.5599 19.1899 84.7899 18.7699 84.9199C18.3599 85.0499 17.8899 85.0799 17.3799 84.9999C16.5199 84.8699 15.8899 84.5599 15.4899 84.0599C15.0799 83.5599 14.9299 82.9799 15.0299 82.3199C15.1399 81.5999 15.4899 81.0499 16.0899 80.6999C16.6899 80.3399 17.4299 80.2299 18.2999 80.3599L18.9399 80.4599C19.0999 80.4799 19.1999 80.4099 19.2199 80.2499L19.3999 79.0099C19.4299 78.8499 19.3599 78.7499 19.1899 78.7299L18.5699 78.6399C17.2199 78.4399 16.0799 78.6499 15.1499 79.2799C14.2199 79.9099 13.6599 80.8499 13.4799 82.0999C13.2999 83.2899 13.5299 84.3099 14.1699 85.1599V85.1599Z" fill="#231F20"/>
        <path d="M28.7299 73.3999C30.0799 73.9199 31.3099 73.9799 32.4199 73.5699C33.5299 73.1599 34.3099 72.3499 34.7799 71.1599C35.2499 69.9499 35.2199 68.8099 34.6799 67.7599C34.1399 66.6999 33.1999 65.9099 31.8399 65.3899L20.0699 60.8299C19.9099 60.7699 19.8099 60.8199 19.7499 60.9699L19.2899 62.1599C19.2299 62.3199 19.2799 62.4199 19.4299 62.4799L31.3899 67.1099C32.2399 67.4399 32.8499 67.9299 33.2099 68.5699C33.5699 69.2099 33.6099 69.8899 33.3299 70.5999C33.0599 71.2999 32.5799 71.7599 31.8799 71.9799C31.1899 72.1999 30.4099 72.1499 29.5599 71.8199L17.5999 67.1899C17.4399 67.1299 17.3399 67.1799 17.2799 67.3299L16.8199 68.5199C16.7599 68.6799 16.8099 68.7799 16.9599 68.8399L28.7299 73.3999V73.3999Z" fill="#231F20"/>
        <path d="M27.5501 54.59C28.2401 55.05 28.9301 55.33 29.6101 55.41C30.2901 55.5 30.9501 55.46 31.5901 55.3C32.2201 55.14 33.0301 54.89 34.0101 54.54C35.0001 54.2 35.7201 53.97 36.1801 53.84C36.6401 53.71 37.1001 53.67 37.5601 53.71C38.0201 53.75 38.4901 53.93 38.9601 54.24C39.6701 54.71 40.1101 55.29 40.2801 55.96C40.4501 56.63 40.3401 57.26 39.9501 57.85C39.5501 58.44 39.0001 58.78 38.3001 58.85C37.6001 58.92 36.8801 58.71 36.1501 58.22L35.7301 57.94C35.5901 57.85 35.4801 57.87 35.3801 58.01L34.7001 59.03C34.6101 59.17 34.6301 59.28 34.7701 59.38L35.2501 59.7C36.3501 60.43 37.4401 60.71 38.5501 60.52C39.6601 60.33 40.5501 59.73 41.2401 58.71C41.9301 57.68 42.1401 56.62 41.8901 55.51C41.6401 54.4 40.9501 53.47 39.8101 52.71C39.1701 52.28 38.5501 52.03 37.9601 51.95C37.3601 51.87 36.7401 51.91 36.0801 52.06C35.4301 52.22 34.5201 52.5 33.3501 52.93C32.4601 53.24 31.7701 53.45 31.2601 53.58C30.7601 53.7 30.2901 53.74 29.8601 53.68C29.4301 53.62 29.0001 53.45 28.5701 53.16C27.8501 52.68 27.4101 52.13 27.2601 51.5C27.1101 50.88 27.2101 50.29 27.5901 49.73C28.0001 49.12 28.5501 48.78 29.2401 48.72C29.9401 48.65 30.6501 48.87 31.3901 49.36L31.9301 49.72C32.0701 49.81 32.1801 49.79 32.2801 49.65L32.9801 48.61C33.0701 48.47 33.0501 48.36 32.9101 48.26L32.3901 47.91C31.2501 47.15 30.1301 46.86 29.0201 47.03C27.9101 47.2 27.0101 47.81 26.3001 48.87C25.6301 49.87 25.4101 50.89 25.6201 51.93C25.8301 52.97 26.4801 53.86 27.5601 54.58L27.5501 54.59Z" fill="#231F20"/>
        <path d="M34.89 40.09C35.01 40.21 35.1201 40.21 35.2401 40.09L37.4501 37.92C37.4501 37.92 37.54 37.87 37.59 37.92L48.5201 49.05C48.6401 49.17 48.75 49.17 48.87 49.05L49.78 48.16C49.9 48.04 49.9 47.93 49.78 47.81L38.85 36.68C38.85 36.68 38.8 36.59 38.85 36.54L41.2001 34.23C41.3201 34.11 41.3201 33.99 41.2001 33.88L40.46 33.13C40.34 33.01 40.2301 33.01 40.1101 33.13L34.14 39C34.02 39.12 34.02 39.23 34.14 39.35L34.8801 40.1L34.89 40.09Z" fill="#231F20"/>
        <path d="M55.01 43.0599C55.04 43.1399 55.09 43.1899 55.15 43.2099C55.21 43.2299 55.28 43.2099 55.35 43.1699L56.42 42.4699C56.56 42.3799 56.59 42.2699 56.53 42.1299L55.36 39.1899C55.36 39.1899 55.34 39.1399 55.36 39.1099C55.37 39.0799 55.39 39.0599 55.42 39.0399L59.21 36.5599C59.21 36.5599 59.27 36.5299 59.3 36.5299C59.33 36.5299 59.35 36.5399 59.37 36.5699L61.59 38.8199C61.7 38.9299 61.82 38.9399 61.94 38.8599L63.03 38.1499C63.16 38.0699 63.18 37.9599 63.1 37.8399L63.07 37.7999L50.59 25.6499C50.49 25.5299 50.38 25.5199 50.24 25.6099L48.99 26.4299C48.86 26.5099 48.83 26.6299 48.88 26.7699L55.04 43.0499L55.01 43.0599ZM51.02 28.2299C51.02 28.2299 51.06 28.2299 51.1 28.2699L58.15 35.3999C58.19 35.4599 58.18 35.4999 58.15 35.5199L54.84 37.6799C54.84 37.6799 54.76 37.6899 54.72 37.6399L51.02 28.3199C51.01 28.2699 51.01 28.2399 51.02 28.2299V28.2299Z" fill="#231F20"/>
        <path d="M67.87 35.6401C67.94 35.7901 68.05 35.8401 68.2 35.7701L69.37 35.2701C69.52 35.2001 69.57 35.0901 69.5 34.9401L62.78 19.3201C62.71 19.1701 62.6 19.1201 62.45 19.1901L61.28 19.6901C61.13 19.7501 61.08 19.8601 61.15 20.0201L67.87 35.6401V35.6401Z" fill="#231F20"/>
        <path d="M76.0601 32.7599L77.3001 32.4799C77.4601 32.4399 77.5301 32.3499 77.4901 32.1799L74.6601 19.6499C74.6601 19.6499 74.6601 19.5699 74.6901 19.5499C74.7201 19.5299 74.7501 19.5499 74.7801 19.5899L82.3601 31.1399C82.4401 31.2599 82.5401 31.2999 82.6701 31.2799L84.1101 30.9599C84.2701 30.9199 84.3401 30.8199 84.3001 30.6599L80.5801 14.0699C80.5401 13.9099 80.4401 13.8399 80.2801 13.8799L79.0601 14.1499C78.9001 14.1899 78.8301 14.2899 78.8701 14.4499L81.6901 27.0099C81.6901 27.0099 81.6901 27.0899 81.6601 27.1099C81.6301 27.1299 81.6001 27.1099 81.5701 27.0699L73.9601 15.4999C73.8801 15.3799 73.7801 15.3399 73.6501 15.3699L72.2401 15.6899C72.0801 15.7299 72.0101 15.8299 72.0501 15.9899L75.7701 32.5799C75.8101 32.7399 75.9101 32.8099 76.0701 32.7699L76.0601 32.7599Z" fill="#231F20"/>
        <path d="M89.5699 30.3001H90.8499C91.0199 30.3001 91.1099 30.2301 91.1299 30.0801L91.7599 26.9801C91.7599 26.9801 91.7699 26.9201 91.7999 26.9101C91.8199 26.8901 91.8499 26.8801 91.8899 26.8801H96.4199C96.4199 26.8801 96.4799 26.8901 96.5099 26.9101C96.5399 26.9301 96.5499 26.9501 96.5499 26.9901L97.1699 30.0901C97.1999 30.2401 97.2899 30.3101 97.4399 30.3101H98.7399C98.8899 30.3101 98.9599 30.2401 98.9599 30.0901V30.0401L95.1699 13.0401C95.1499 12.8901 95.0599 12.8201 94.8899 12.8201H93.3899C93.2399 12.8201 93.1499 12.8901 93.1199 13.0401L89.3599 30.0401C89.3399 30.1201 89.3599 30.1901 89.3999 30.2401C89.4399 30.2901 89.4999 30.3201 89.5899 30.3201L89.5699 30.3001ZM94.0799 15.5001C94.0799 15.5001 94.1099 15.4201 94.1299 15.4201C94.1499 15.4201 94.1599 15.4501 94.1799 15.4901L96.1699 25.3201C96.1699 25.3901 96.1399 25.4201 96.0899 25.4201H92.1399C92.1399 25.4201 92.0599 25.3801 92.0599 25.3201L94.0699 15.5001H94.0799Z" fill="#231F20"/>
        <path d="M104.22 31.02L107.96 31.84C109.29 32.13 110.45 31.96 111.43 31.32C112.41 30.68 113.05 29.63 113.36 28.16C113.79 26.22 113.37 24.68 112.13 23.54C112.12 23.52 112.11 23.5 112.12 23.49C112.12 23.47 112.14 23.46 112.18 23.45C113.68 23.08 114.63 21.98 115.03 20.16C115.35 18.73 115.19 17.54 114.56 16.59C113.93 15.64 112.92 15.01 111.52 14.7L108 13.93C107.84 13.89 107.74 13.96 107.7 14.12L104.04 30.72C104 30.88 104.07 30.98 104.23 31.02H104.22ZM109.12 15.87C109.13 15.81 109.17 15.78 109.24 15.79L111.17 16.21C112.05 16.4 112.67 16.83 113.04 17.48C113.41 18.13 113.48 18.95 113.27 19.92C113.06 20.86 112.66 21.54 112.05 21.96C111.45 22.37 110.71 22.48 109.83 22.29L107.9 21.87C107.83 21.86 107.81 21.82 107.82 21.75L109.12 15.87V15.87ZM107.49 23.27C107.5 23.21 107.54 23.18 107.61 23.19L109.64 23.64C110.5 23.83 111.11 24.27 111.47 24.97C111.83 25.66 111.89 26.54 111.66 27.6C111.43 28.64 111.01 29.4 110.39 29.87C109.77 30.34 109.03 30.48 108.17 30.29L106.14 29.84C106.08 29.83 106.05 29.79 106.06 29.72L107.48 23.27H107.49Z" fill="#231F20"/>
        <path d="M124.52 37.9099C124.67 37.9799 124.78 37.9399 124.85 37.7899L125.29 36.8299C125.36 36.6799 125.32 36.5699 125.17 36.4999L119.96 34.1199C119.9 34.0899 119.88 34.0499 119.91 33.9899L126.4 19.7999C126.47 19.6499 126.43 19.5399 126.28 19.4699L125.12 18.9399C124.97 18.8699 124.86 18.9099 124.79 19.0599L117.72 34.5199C117.65 34.6699 117.69 34.7799 117.84 34.8499L124.52 37.9099V37.9099Z" fill="#231F20"/>
        <path d="M135.19 45.22C135.32 45.32 135.44 45.3 135.54 45.17L136.16 44.33C136.26 44.2 136.24 44.08 136.11 43.98L131.44 40.54C131.39 40.5 131.38 40.45 131.42 40.4L135.12 35.39C135.16 35.34 135.21 35.33 135.26 35.37L138.2 37.54C138.33 37.64 138.45 37.62 138.55 37.49L139.19 36.63C139.29 36.5 139.27 36.38 139.14 36.28L136.2 34.11C136.15 34.07 136.14 34.02 136.18 33.97L139.86 28.98C139.9 28.93 139.95 28.92 140 28.96L144.67 32.41C144.8 32.51 144.92 32.49 145.02 32.36L145.64 31.51C145.74 31.38 145.72 31.26 145.59 31.16L139.62 26.75C139.49 26.65 139.37 26.67 139.27 26.8L129.17 40.48C129.07 40.61 129.09 40.73 129.22 40.83L135.19 45.24V45.22Z" fill="#231F20"/>
        <path d="M48.38 140.45C48.48 139.32 48.09 138.28 47.22 137.33L44.61 134.5C44.5 134.38 44.38 134.37 44.26 134.49L31.77 146.02C31.65 146.13 31.64 146.25 31.76 146.37L34.37 149.2C35.24 150.14 36.24 150.61 37.38 150.6C38.52 150.59 39.61 150.11 40.65 149.15L46.67 143.59C47.71 142.63 48.28 141.58 48.38 140.45V140.45ZM45.4 142.32L39.49 147.78C38.77 148.42 38.05 148.78 37.33 148.84C36.61 148.9 36.01 148.66 35.51 148.12L34 146.52C34 146.52 33.96 146.43 34 146.38L44.43 136.75C44.43 136.75 44.53 136.71 44.57 136.75L46.06 138.36C46.58 138.92 46.78 139.56 46.66 140.26C46.54 140.96 46.12 141.65 45.39 142.32H45.4Z" fill="#231F20"/>
        <path d="M56.08 145.37C55.94 145.28 55.83 145.3 55.73 145.44L46.37 159.63C46.28 159.77 46.3 159.88 46.44 159.98L52.64 164.07C52.78 164.16 52.89 164.14 52.99 164L53.57 163.12C53.66 162.98 53.64 162.87 53.5 162.77L48.66 159.58C48.6 159.54 48.6 159.5 48.63 159.44L52.06 154.24C52.1 154.19 52.14 154.18 52.2 154.21L55.25 156.22C55.39 156.31 55.5 156.29 55.6 156.15L56.19 155.25C56.28 155.11 56.26 154.99 56.12 154.9L53.07 152.89C53.02 152.85 53.01 152.81 53.04 152.75L56.45 147.57C56.49 147.52 56.53 147.51 56.59 147.54L61.43 150.73C61.57 150.82 61.68 150.8 61.78 150.66L62.36 149.78C62.45 149.64 62.43 149.53 62.29 149.43L56.09 145.34L56.08 145.37Z" fill="#231F20"/>
        <path d="M75.32 155.03C75.18 154.98 75.07 155.02 74.99 155.15L67.75 167.4C67.75 167.4 67.7 167.44 67.69 167.43C67.67 167.43 67.66 167.4 67.66 167.37L69.84 153.3C69.88 153.15 69.81 153.05 69.66 153L68.34 152.53C68.2 152.48 68.1 152.53 68.05 152.67L68.03 152.72L65.7 169.95C65.68 170.1 65.74 170.2 65.88 170.25L67.22 170.73C67.38 170.79 67.49 170.75 67.55 170.61L76.71 155.84C76.8 155.68 76.76 155.57 76.59 155.51L75.32 155.05V155.03Z" fill="#231F20"/>
        <path d="M91.35 157.84L83.97 157.05C83.8 157.03 83.71 157.11 83.69 157.27L81.87 174.17C81.85 174.34 81.93 174.43 82.09 174.45L89.47 175.24C89.64 175.26 89.73 175.18 89.75 175.02L89.86 173.98C89.88 173.81 89.8 173.72 89.64 173.7L83.87 173.08C83.8 173.08 83.77 173.04 83.78 172.97L84.44 166.78C84.44 166.71 84.48 166.68 84.55 166.69L88.18 167.08C88.35 167.1 88.44 167.02 88.46 166.86L88.57 165.79C88.59 165.63 88.51 165.53 88.35 165.51L84.72 165.12C84.65 165.12 84.62 165.08 84.63 165.01L85.29 158.85C85.29 158.78 85.33 158.75 85.4 158.76L91.17 159.38C91.34 159.4 91.43 159.33 91.45 159.16L91.56 158.12C91.58 157.95 91.5 157.86 91.34 157.84H91.35Z" fill="#231F20"/>
        <path d="M108.4 172.53L102.72 173.25C102.65 173.25 102.62 173.23 102.61 173.16L100.65 157.68C100.63 157.52 100.54 157.44 100.37 157.46L99.11 157.62C98.94 157.64 98.87 157.73 98.89 157.9L101.03 174.76C101.05 174.93 101.14 175 101.31 174.98L108.6 174.06C108.77 174.04 108.84 173.95 108.82 173.78L108.69 172.74C108.67 172.57 108.58 172.5 108.41 172.52L108.4 172.53Z" fill="#231F20"/>
        <path d="M122.04 156.01C121.57 154.78 120.82 153.93 119.79 153.47C118.76 153 117.64 153 116.42 153.47C115.22 153.93 114.39 154.67 113.93 155.69C113.47 156.72 113.48 157.85 113.94 159.08L117.18 167.64C117.65 168.87 118.39 169.72 119.42 170.19C120.44 170.66 121.56 170.66 122.76 170.21C123.97 169.75 124.81 169 125.28 167.96C125.74 166.92 125.74 165.79 125.28 164.58L122.04 156.02V156.01ZM123.68 167.38C123.42 168.02 122.93 168.48 122.21 168.75C121.51 169.02 120.85 168.99 120.23 168.68C119.61 168.37 119.16 167.84 118.88 167.09L115.58 158.37C115.29 157.61 115.27 156.91 115.53 156.26C115.79 155.62 116.27 155.17 116.97 154.9C117.69 154.63 118.36 154.65 118.98 154.95C119.6 155.26 120.06 155.79 120.35 156.56L123.65 165.28C123.94 166.03 123.94 166.72 123.68 167.37V167.38Z" fill="#231F20"/>
        <path d="M134.18 155.53C134.14 155.48 134.15 155.43 134.21 155.39L136.33 153.93C137.32 153.25 137.87 152.34 137.97 151.2C138.08 150.06 137.72 148.91 136.91 147.73C136.09 146.54 135.11 145.79 133.99 145.48C132.87 145.17 131.8 145.36 130.8 146.05L127.41 148.39C127.27 148.48 127.25 148.6 127.35 148.74L137.02 162.75C137.12 162.89 137.23 162.91 137.37 162.81L138.42 162.09C138.56 162 138.58 161.88 138.48 161.74L134.19 155.53H134.18ZM133.24 154.17L129.59 148.88C129.55 148.83 129.56 148.78 129.62 148.74L131.49 147.45C132.11 147.02 132.78 146.93 133.51 147.16C134.24 147.4 134.89 147.92 135.46 148.75C136.03 149.57 136.29 150.36 136.25 151.12C136.21 151.88 135.88 152.47 135.26 152.9L133.39 154.19C133.34 154.23 133.29 154.22 133.25 154.17H133.24Z" fill="#231F20"/>
        <path d="M145.66 132.1C145.53 131.99 145.42 132 145.31 132.13L144.46 133.12C144.36 133.23 144.34 133.35 144.4 133.46L146.95 139.36C146.95 139.36 146.95 139.42 146.94 139.45C146.92 139.48 146.89 139.48 146.85 139.48L140.61 137.88C140.49 137.84 140.38 137.88 140.28 137.99L139.43 138.98C139.32 139.11 139.33 139.23 139.46 139.33L152.37 150.4C152.5 150.51 152.61 150.5 152.72 150.37L153.55 149.4C153.66 149.27 153.65 149.15 153.52 149.05L143.37 140.34C143.37 140.34 143.32 140.28 143.34 140.26C143.36 140.24 143.39 140.23 143.43 140.23L149.2 141.76C149.32 141.8 149.42 141.76 149.51 141.67C149.59 141.58 149.6 141.48 149.54 141.37L147.13 135.88C147.13 135.88 147.13 135.8 147.15 135.78C147.17 135.76 147.19 135.77 147.23 135.8L157.4 144.52C157.53 144.63 157.64 144.62 157.75 144.49L158.58 143.52C158.69 143.4 158.68 143.28 158.55 143.17L145.64 132.1H145.66Z" fill="#231F20"/>
        <path d="M167.7 125.29C167.55 125.22 167.44 125.26 167.36 125.4L164.81 130.61C164.78 130.67 164.74 130.69 164.68 130.66L159.09 127.92C159.03 127.89 159.02 127.85 159.05 127.79L160.66 124.51C160.73 124.36 160.7 124.25 160.55 124.18L159.58 123.71C159.43 123.64 159.32 123.67 159.25 123.83L157.64 127.11C157.61 127.17 157.57 127.19 157.51 127.16L151.94 124.43C151.88 124.4 151.87 124.36 151.9 124.3L154.45 119.09C154.52 118.94 154.48 118.83 154.33 118.76L153.39 118.3C153.24 118.23 153.13 118.27 153.06 118.42L149.79 125.09C149.72 125.24 149.76 125.35 149.91 125.42L165.18 132.9C165.33 132.97 165.44 132.94 165.51 132.78L168.78 126.11C168.85 125.96 168.82 125.85 168.67 125.78L167.73 125.32L167.7 125.29Z" fill="#231F20"/>
        <path d="M174.17 106.41L157.54 102.89C157.38 102.85 157.28 102.92 157.24 103.08L156.98 104.3C156.95 104.46 157.01 104.56 157.17 104.6L169.77 107.27C169.77 107.27 169.84 107.3 169.85 107.34C169.85 107.38 169.83 107.4 169.77 107.4L156.09 109.51C155.95 109.53 155.87 109.61 155.84 109.74L155.54 111.16C155.5 111.32 155.57 111.42 155.73 111.46L172.36 114.98C172.52 115.01 172.62 114.95 172.66 114.79L172.92 113.54C172.95 113.38 172.89 113.28 172.73 113.24L160.15 110.6C160.15 110.6 160.08 110.57 160.07 110.53C160.07 110.5 160.09 110.48 160.15 110.47L173.8 108.38C173.94 108.36 174.02 108.28 174.05 108.15L174.35 106.71C174.39 106.55 174.32 106.45 174.16 106.41H174.17Z" fill="#231F20"/>
        <path d="M175.18 90.2201C175.18 90.0501 175.09 89.9701 174.92 89.9801L159.33 90.6001C159.26 90.6001 159.23 90.5701 159.23 90.5001L159.1 87.2001C159.1 87.0301 159.01 86.9501 158.84 86.9601L157.79 87.0001C157.62 87.0001 157.54 87.0901 157.55 87.2601L157.88 95.6301C157.88 95.8001 157.97 95.8801 158.14 95.8701L159.19 95.8301C159.36 95.8301 159.44 95.7401 159.43 95.5701L159.31 92.4701C159.31 92.4001 159.34 92.3701 159.41 92.3701L175 91.7501C175.17 91.7501 175.25 91.6601 175.24 91.4901L175.19 90.2201H175.18Z" fill="#231F20"/>
        <path d="M94 0C42.17 0 0 42.17 0 94C0 145.83 42.17 188 94 188C145.83 188 188 145.83 188 94C188 42.17 145.83 0 94 0ZM94 1.5C131.85 1.5 164.45 24.35 178.76 56.98L141.9 72.72C133.72 54.38 115.33 41.56 93.99 41.56C65.08 41.56 41.56 65.08 41.56 93.99C41.56 101.03 42.96 107.75 45.49 113.89L8.64 129.63C4.04 118.65 1.49 106.61 1.49 93.99C1.5 43 43 1.5 94 1.5ZM46.88 113.31C44.43 107.35 43.07 100.83 43.07 94C43.07 65.91 65.92 43.07 94 43.07C114.72 43.07 132.58 55.51 140.53 73.32L46.88 113.31ZM141.12 74.69C143.57 80.65 144.93 87.17 144.93 94C144.93 122.08 122.08 144.93 94 144.93C73.28 144.93 55.42 132.49 47.47 114.68L141.12 74.68V74.69ZM94 186.5C56.15 186.5 23.55 163.65 9.24 131.02L46.09 115.28C54.27 133.62 72.66 146.44 94 146.44C122.91 146.44 146.43 122.92 146.43 94.01C146.43 86.97 145.03 80.25 142.5 74.11L179.35 58.37C183.95 69.35 186.5 81.39 186.5 94.01C186.5 145.01 145 186.51 94 186.51V186.5Z" fill="#231F20"/>
    </svg>
</template>

<script>
export default {
    name: 'SealDurable',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
